import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { get, isEmpty } from "lodash";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import UserInfoRow from "../../components/UserInfoRow";
import { callRestApi } from "../../utils/callRestApi";
import SimpleTable from "../../components/SimpleTable";
import PawnPositions from "./PawnPositions";
import { Confirm, Link } from "react-admin";
import { CloseRounded } from "@mui/icons-material";
import GridItem from "../../components/GridItem";


const ViewMegaTournament = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tournamentInfo, setTournamentInfo] = useState<any>({});
  const [winningDetails, setWinningDetails] = useState<any[]>([]);
  const [leaderboardInfo, setLeaderboardInfo] = useState<any[]>([]);
  const [isCancelTournamentConfirmOpen, setIsCancelTournamentConfirmOpen] =
    useState(false);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);
  const { id = "" } = useParams();

  useEffect(() => {
    fetchTournamentDetails(id);
    // eslint-disable-next-line
  }, []);

  const fetchTournamentDetails = async (id: string) => {
    setLoading(true);
    try {
      const { data } = (await callRestApi(
        `/api/mega-tournament/${id}`,
        "GET",
        {}
      )) as AxiosResponse;
      // Set tournament information
      setTournamentInfo(data.megaTournamentDetails);

      // Map over the winningPrizes array to add an id property
      const winningPrizesWithId = data.megaTournamentDetails.winningPrizes.map((prize: any, index: number) => ({
        ...prize,
        _id: index + 1,
      }));

      // Set the winning details state
      setWinningDetails(winningPrizesWithId);
      setLeaderboardInfo(data.leaderboardDetails.map((item: any) => ({
        ...item,
        score: item.score.toFixed(2),
      })));
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  const handleCancelTournament = async () => {
    try {
      setConfirmModalLoading(true);
      const { status } = (await callRestApi(
        `/ludo/mega-tournament/${id}/cancel`,
        "POST",
        { reason: "The tournament has been canceled. Your refund will be credited to your wallet shortly." }
      )) as AxiosResponse;
      if (status === 201) {
        toast("Tournament Cancelled Successfully", {
          type: "success",
        });
      }
        setTimeout(function() {
          window.location.reload();
      }, 1000);
      setIsCancelTournamentConfirmOpen(false);
      setConfirmModalLoading(false);
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setConfirmModalLoading(false);
  };

  const useStyles: any = makeStyles(() => ({
    live: {
      color: "green",
      fontWeight: "bold",
    },
    ended: {
      color: "red",
      fontWeight: "bold",
    },
    canceled: {
      color: "red",
      fontWeight: "bold",
    },
    boldText: {
      fontWeight: "bold",
    },
  }));

  const classes = useStyles();

  const getStatusText = (status: any) => {
    if (status === "live") {
      return <span className={classes.live}>LIVE</span>;
    } else if (status === "canceled") {
      return <span className={classes.canceled}>CANCELED</span>;
    } else if (status === "completed") {
      return <span className={classes.ended}>COMPLETED</span>;
    } else {
      return <span>{status}</span>;
    }
  };

  const winninPrizesColumns = [
    { field: "minRank", headerName: "Min Rank", flex: 1 },
    { field: "maxRank", headerName: "Max Rank", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "percentage", headerName: "Percentage", flex: 1 },
  ];

  const leaderBoardColumns = [
    { field: "rank", headerName: "Rank", flex: 1 },
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
      renderCell: (params: any) => (
        <Link to={`/api/users/${params.row.userId}/show`}>
          {params.value}
        </Link>
      )
    },
    { field: "tableId", headerName: "Table Id", flex: 1 },
    { field: "entryNo", headerName: "Entry No", flex: 1 },
    { field: "score", headerName: "Score", flex: 1 },
    { field: "winAmount", headerName: "Winning Amount", flex: 1 },
  ];

  // eslint-disable-next-line
  const tournamentStatus = get(tournamentInfo, "status", "");

  return (
    <Grid container spacing={1} style={{ marginTop: "15px" }}>
      <Grid item xs={3.5}>
        <GridItem>
          <Box p={2} border={1} borderColor="grey.300">
            <h4 style={{ textAlign: "center" }}>Mega Tournament Details</h4>
            <hr />
            <UserInfoRow label="Tournament Id" value={get(tournamentInfo, "_id", "-")} />
            <UserInfoRow label="Tournament Name" value={<span className={classes.boldText}>{get(tournamentInfo, "name", "-")}</span>} />
            <UserInfoRow label="Alias Name" value={get(tournamentInfo, "alias", "-")} />
            <UserInfoRow label="Max Total Entries" value={get(tournamentInfo, "maxTotalEntries", "-")} />
            <UserInfoRow label="Max Entries Per User" value={get(tournamentInfo, "maxEntriesPerUser", "-")} />
            <UserInfoRow label="Total Moves" value={get(tournamentInfo, "totalMoves", "-")} />
            <UserInfoRow label="Max Extension Limit" value={get(tournamentInfo, "maxExtensionLimit", "-")} />
            <UserInfoRow label="Entered User Count" value={get(tournamentInfo, "enteredUserCount", "-")} />
            <UserInfoRow label="Highest Score" value={parseFloat(get(tournamentInfo, "highestScore", 0)).toFixed(2)} />
            <UserInfoRow label="Extended Count" value={get(tournamentInfo, "extendedCount", "-")} />
            <UserInfoRow label="Extension Time" value={get(tournamentInfo, "extensionTime", "-")} />
            <UserInfoRow label="Join fees" value={get(tournamentInfo, "joinFee", "-")} />
            <UserInfoRow label="Status" value={getStatusText(get(tournamentInfo, "status", "-"))} />
            <UserInfoRow label="Created At" value={dayjs(get(tournamentInfo, "createdAt")).format("MMMM D, YYYY h:mm A")} />
            <UserInfoRow label="Ends At" value={dayjs(get(tournamentInfo, "endAt")).format("MMMM D, YYYY h:mm A")} />
            {!["ended", "canceled", "completed"].includes(tournamentStatus) && (
              <Button
                fullWidth
                style={{ marginTop: "10px" }}
                color="error"
                variant="contained"
                onClick={() => setIsCancelTournamentConfirmOpen(true)}
              >
                <CloseRounded />
                Cancel Tournament
              </Button>
            )}
          </Box>
        </GridItem>
        <Box p={2} border={1} borderColor="grey.300" mt={2}>
          <h3>Prize Pool</h3>
          <SimpleTable columns={winninPrizesColumns} data={winningDetails} />
        </Box>
      </Grid>
      <Grid item xs={8.5}>
        <Box p={2} border={1} borderColor="grey.300">
          <Grid style={{ display: 'flex' }}>
            <h3>Leaderboard</h3>
          </Grid>
          <SimpleTable columns={leaderBoardColumns} data={leaderboardInfo} />
        </Box>
      </Grid>
      {isCancelTournamentConfirmOpen && (
        <Confirm
          isOpen={isCancelTournamentConfirmOpen}
          title="Are you sure you want to cancel the tournament?"
          content= ""
          onConfirm={() => {
            handleCancelTournament();
            setIsCancelTournamentConfirmOpen(false);
          }}
          onClose={() => {
            setIsCancelTournamentConfirmOpen(false)
          }}
          loading={confirmModalLoading}
        />
      )}
    </Grid>
  );
};

export default ViewMegaTournament;
