import { memo, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Confirm, useNotify, usePermissions } from "react-admin";
import { toast } from "react-toastify";
import { get, isEmpty, toUpper } from "lodash";
import {
  GridCellParams,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  styled,
} from "@mui/material";

import UserInfoRow from "../../components/UserInfoRow";
import StatusField from "../../components/StatusField";
import SimpleTable from "../../components/SimpleTable";
import WalletTransactionModal from "../../components/WalletTransactionModal";
import ManageRoleModal from "./ManageRoleModal";
import { callRestApi } from "../../utils/callRestApi";
import { RemoveRedEye } from "@mui/icons-material";
import GameDetailsModal from "./GameDetailsModal";
import React from "react";

const Details = () => {
  const { id } = useParams();
  const notify = useNotify();
  const { permissions } = usePermissions();

  const navigate = useNavigate();
  const handleLinkClick = (path: any) => {
    navigate(path);
    window.location.reload();
  };

  const [userData, setUserData] = useState<{
    isBlocked: boolean;
    _id: string;
    userDetails: object;
  } | null>(null);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gameDetailsModal, setgameDetailsModal] = useState(false);
  const [gameDetails, setGameDetails] = useState();
  const [orderId] = useState();
  const [tableId] = useState();
  const [openAddMoney, setOpenAddMoney] = useState(false);
  const [openDeductMoney, setOpenDeductMoney] = useState(false);
  const [openManageRoles, setOpenManageRoles] = useState(false);

  const userId = get(userData, "userDetails.userId", "");
  const isBlocked = get(userData, "userDetails.isBlocked", true);

  useEffect(() => {
    fetchUserDetails(id);
    return () => {};
    // eslint-disable-next-line
  }, []);

  const fetchUserDetails = async (id: any) => {
    setLoading(true);
    try {
      const userRes = await callRestApi(`/api/users/${id}`, "GET", {});
      setUserData(get(userRes, "data"));
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  const gameHistoriesColumns = [
    {
      field: "tableId",
      headerName: "Table Id",
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.tableId || "-",
    },
    {
      field: "joinFee",
      headerName: "Join Fee",
      type: "number",
      flex: 0.6,
      valueGetter: (params: GridValueGetterParams) => params.row.joinFee || "-",
    },
    {
      field: "gameType",
      headerName: "Game Type",
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.gameType || "-",
    },
    {
      field: "winLoseAmount",
      headerName: "Win/Loss Amount",
      type: "number",
      flex: 0.6,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.winLoseAmount || "-",
    },
    {
      field: "outcome",
      headerName: "Outcome",
      flex: 0.6,
      valueGetter: (params: GridValueGetterParams) => params.row.outcome || "-",
    },
    {
      field: "roomSize",
      headerName: "Room Size",
      type: "number",
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.roomSize || "-",
    },
    {
      field: "startAmount",
      headerName: "Start Amount",
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.startAmount || "-",
    },
    {
      field: "endAmount",
      headerName: "End Amount",
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.endAmount || "-",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.6,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.createdAt
          ? new Date(params.row.createdAt).toLocaleString()
          : "-",
    },
  ];

  const withdrawalsColumns = [
    { field: "orderId", headerName: "Order Id", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 0.5 },
    { field: "settledAmount", headerName: "Settled Amount", flex: 0.5 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
    { field: "status", headerName: "Status", flex: 0.5 },
  ];

  const depositColumns = [
    { field: "orderId", headerName: "Order Id", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 0.5 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
    { field: "status", headerName: "Status", flex: 0.5 },
  ];

  const bonusTransactionColumns = [
    { field: "amount", headerName: "Amount", flex: 0.7 },
    {
      field: "type",
      headerName: "Type",
      flex: 0.7,
      valueGetter: (params: any) => {
        const type = params.row.type;
        return type === "signupBonus"
          ? "Sign Up"
          : type === "coupon"
          ? "Coupon"
          : "";
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.updatedAt).toLocaleString()}`,
    },
  ];
  const refundTransactionColumns = [
    {
      field: "type",
      headerName: "Type",
      flex: 0.7,
      valueGetter: (params: any) => {
        const type = params.row.type;
        return type === "adminRefund"
          ? "Admin"
          : type === "ludoTournamentRefund"
          ? "Tournament"
          : "";
      },
    },
    {
      field: "Table/Order/Tournament Id",
      headerName: "Table/Order/Tournament Id",
      flex: 1.4,
      renderCell: (params: GridCellParams) => {
        const { tableId, orderId, tournamentId } = params.row;
        if (tournamentId) {
          return (
            <Link
              to={`/ludo/tournament/${tournamentId}/show`}
              onClick={() =>
                handleLinkClick(`/ludo/tournament/${tournamentId}/show`)
              }
            >
              {tournamentId}
            </Link>
          );
        } else if (tableId) {
          return tableId;
        } else if (orderId) {
          return orderId;
        } else {
          return "-";
        }
      },
    },
    { field: "amount", headerName: "Amount", flex: 0.5 },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        const row = params.row;
        return `${new Date(row.createdAt).toLocaleString()}`;
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        const row = params.row;
        return `${new Date(row.updatedAt).toLocaleString()}`;
      },
    },
  ];
  const tournamentsUsersColumns = [
    {
      field: "tournamentId",
      headerName: "Tournament ID",
      flex: 0.7,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/all-tournaments/${row.tournamentId}/show`}
          onClick={() =>
            handleLinkClick(`/all-tournaments/${row.tournamentId}/show`)
          }
        >
          {row.tournamentId}
        </Link>
      ),
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const handleBlock = async (_id: string, isBlocked: boolean) => {
    try {
      setLoading(true);
      const endpoint = "/users/change-block-status";
      const url = `${endpoint}`;
      await callRestApi(url, "PATCH", { userId: _id, shouldBlock: !isBlocked });

      setUserData((prevUserData) => {
        if (prevUserData && prevUserData.userDetails) {
          return {
            ...prevUserData,
            userDetails: {
              ...prevUserData.userDetails,
              isBlocked: !isBlocked,
            },
          };
        }
        return prevUserData;
      });

      toast(
        isBlocked
          ? "Unblocked User Successfully!"
          : "Blocked User Successfully!",
        {
          type: "success",
        }
      );
    } catch (e) {
      toast("Something Went Wrong!", {
        type: "error",
      });
    } finally {
      setLoading(false);
      setIsConfirmModalOpen(false);
    }
  };

  const handleEyeClick = (game: any) => {
    setgameDetailsModal(true);
    setGameDetails(() => get(userData, `userDetails.stats.${game}`));
  };

  const renderGameRow = (label: any, game: any) => {
    const winMatches = get(userData, `userDetails.stats.${game}.winMatches`, 0);
    const lossMatches = get(userData, `userDetails.stats.${game}.lossMatches`, 0);
    const totalMatches = winMatches + lossMatches;

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '2px',
        }}
      >
        <UserInfoRow
          label={label}
          value={totalMatches}
        />
        <RemoveRedEye
          titleAccess={`View Game Details for ${label}`}
          color="primary"
          onClick={() => handleEyeClick(game)}
          fontSize="small"
          sx={{ '&:hover': { color: 'green' }, cursor: 'pointer' }}
        />
      </Box>
    );
  };

  const formatWalletValue = (value: any) => {
    const stringValue = String(value);
    const decimalIndex = stringValue.indexOf(".");
    if (decimalIndex !== -1) {
      return stringValue.slice(0, decimalIndex + 3);
    }
    return stringValue;
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isEmpty(userData)) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <h3 style={{ marginLeft: "24px" }}>User Details</h3>
            <Item>
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                ></Box>
                <Box>
                  <UserInfoRow
                    label="User Name"
                    value={get(userData, "userDetails.username", "")}
                  />
                </Box>
                <Box>
                  <UserInfoRow
                    label="Full Name"
                    value={get(userData, "userDetails.name", "")}
                  />
                  <UserInfoRow
                    label="Mobile Number"
                    value={`${get(
                      userData,
                      "userDetails.mobileNo.countryCode"
                    )}-${get(userData, "userDetails.mobileNo.number")}`}
                  />
                  {get(userData, "email", "") && (
                    <UserInfoRow
                      label="E-Mail Id"
                      value={`${get(userData, "userDetails.email", "")}`}
                    />
                  )}
                  {get(userData, "email", "") && (
                    <UserInfoRow
                      label="E-Mail Verified"
                      value={
                        <StatusField
                          value={get(userData, "isEmailVerified", false)}
                          booleanLabel={
                            get(userData, "isEmailVerified", false)
                              ? "Verified"
                              : "Not Verified"
                          }
                          source="boolean"
                        />
                      }
                    />
                  )}
                  <UserInfoRow
                    label="Referral Code"
                    value={`${get(userData, "userDetails.referral.code", 0)}`}
                  />
                  <UserInfoRow
                    label="Referred Users"
                    value={`${get(userData, "userDetails.referral.count", 0)}`}
                  />
                  <UserInfoRow
                    label="Referral earnings"
                    value={`₹${Number(
                      get(userData, "userDetails.referral.earning", 0)
                    ).toFixed(2)}`}
                  />
                  <UserInfoRow
                    label="Main Wallet"
                    value={formatWalletValue(get(userData, "userDetails.wallet.main", 0))}
                  />
                  <UserInfoRow
                    label="Win Wallet"
                    value={formatWalletValue(get(userData, "userDetails.wallet.win", 0))}
                  />
                  <UserInfoRow
                    label="Bonus Wallet"
                    value={formatWalletValue(get(userData, "userDetails.wallet.bonus", 0))}
                  />
                  <UserInfoRow
                    label="KYC Status"
                    value={
                      <StatusField
                        value={
                          get(userData, "userDetails.kyc.status") == true
                            ? "COMPLETED"
                            : "PENDING"
                        }
                        source="kyc"
                      />
                    }
                  />

                </Box>
                {permissions.includes("admin") && (
                  <Box marginTop={2} display="flex" flexDirection="column">
                    {get(userData, "userDetails.isBlocked") ? (
                      <Button
                        variant="contained"
                        color="success"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          setIsConfirmModalOpen(true);
                        }}
                      >
                        UnBlock User
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setIsConfirmModalOpen(true);
                        }}
                      >
                        Block User
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="success"
                      sx={{ mt: 1 }}
                      onClick={() => setOpenAddMoney(true)}
                    >
                      Add Money
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ mt: 1 }}
                      onClick={() => setOpenDeductMoney(true)}
                    >
                      Deduct Money
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 1 }}
                      onClick={() => setOpenManageRoles(true)}
                    >
                      Manage Roles
                    </Button>
                  </Box>
                )}
              </Box>
            </Item>

            <h3 style={{ marginLeft: "24px" }}>Device Information</h3>
            <Item>
              <Box>
                <UserInfoRow
                  label="Device Id"
                  value={get(userData, "userDetails.device.deviceId", "-")}
                />
                <UserInfoRow
                  label="Device Model"
                  value={get(userData, "userDetails.device.model", "-")}
                />
                <UserInfoRow
                  label="Operating System"
                  value={get(userData, "userDetails.device.os", "-")}
                />
                <UserInfoRow
                  label="RAM (MB)"
                  value={`${get(userData, "userDetails.device.ram", "-")}`}
                />
                <UserInfoRow
                  label="Processor"
                  value={get(userData, "userDetails.device.processor", "-")}
                />
                <UserInfoRow
                  label="Graphics Device Name"
                  value={get(
                    userData,
                    "userDetails.device.graphicsDeviceName",
                    "-"
                  )}
                />

                <UserInfoRow
                  label="Graphics Device ID"
                  value={get(
                    userData,
                    "userDetails.device.graphicsDeviceID",
                    "-"
                  )}
                />
              </Box>
            </Item>

            <h3 style={{ marginLeft: "24px" }}>Build Information</h3>
            <Item>
              <Box>
                <UserInfoRow
                  label="App Code"
                  value={get(userData, "userDetails.buildInfo.appCode", "-")}
                />
                <UserInfoRow
                  label="App Version"
                  value={get(userData, "userDetails.buildInfo.appVersion", "-")}
                />
                <UserInfoRow
                  label="PlayStore Build"
                  value={`${get(
                    userData,
                    "userDetails.buildInfo.isPlayStoreBuild",
                    "-"
                  )}`}
                />
                <UserInfoRow
                  label="Global Build"
                  value={`${get(
                    userData,
                    "userDetails.buildInfo.isGlobalBuild",
                    "-"
                  )}`}
                />
                <UserInfoRow
                  label="Install Source"
                  value={get(
                    userData,
                    "userDetails.buildInfo.installSource",
                    "-"
                  )}
                />
                <UserInfoRow
                  label="Install Channel"
                  value={get(
                    userData,
                    "userDetails.buildInfo.installChannel",
                    "-"
                  )}
                />
              </Box>
            </Item>

            <h3 style={{ marginLeft: "24px" }}>Referred Usernames</h3>
            <Item>
              <Box>
                <div
                  style={{
                    margin: "15px",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "10px",
                    color: "black",
                  }}
                >
                  {Array.isArray(get(userData, "referredUsers", [])) &&
                  get(userData, "referredUsers", []).length > 0 ? (
                    get(userData, "referredUsers", []).map((username) => (
                      <React.Fragment key={username}>
                        <div>{username}</div>
                      </React.Fragment>
                    ))
                  ) : (
                    <div>No referred users</div>
                  )}
                </div>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={9}>
            <h3 style={{ marginLeft: "24px" }}>Games Played</h3>
            <Item>
              <SimpleTable
                columns={gameHistoriesColumns}
                data={[]}
                filter={{ userId: id }}
                endPointData={{
                  apiEndpoint: "",
                }}
                dataMode="server"
              />
            </Item>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <h3 style={{ marginLeft: "24px" }}>Withdrawals</h3>
                <Item>
                  <SimpleTable
                    columns={withdrawalsColumns}
                    data={[]}
                    filter={{
                      userId: id,
                      mode: "withdrawal",
                    }}
                    endPointData={{
                      apiEndpoint: "/api/payment",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <h3 style={{ marginLeft: "24px" }}>Deposits</h3>
                <Item>
                  <SimpleTable
                    columns={depositColumns}
                    data={[]}
                    filter={{
                      userId: id,
                      mode: "deposit",
                    }}
                    endPointData={{
                      apiEndpoint: "/api/payment",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>

              <Grid item xs={6}>
                <h4 style={{ marginLeft: "24px" }}>
                  Bonus Wallet Transactions
                </h4>
                <Item>
                  <SimpleTable
                    columns={bonusTransactionColumns}
                    data={get(userData, "depositHistory", [])}
                    filter={{
                      userId: id,
                      type: ["signupBonus", "coupon"],
                    }}
                    endPointData={{
                      apiEndpoint: "/api/transactions",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>

              <Grid item xs={6}>
                <h4 style={{ marginLeft: "24px" }}>Refund Transactions</h4>
                <Item>
                  <SimpleTable
                    columns={refundTransactionColumns}
                    data={[]}
                    filter={{
                      userId: id,
                      type: ["adminRefund", "ludoTournamentRefund"],
                    }}
                    endPointData={{
                      apiEndpoint: "/api/transactions",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {gameDetailsModal && (
          <GameDetailsModal
            open={gameDetailsModal}
            close={setgameDetailsModal}
            data={gameDetails}
          />
        )}
        {isConfirmModalOpen && (
          <Confirm
            isOpen={isConfirmModalOpen}
            title={`Please Confirm the Action`}
            content="Are you sure?"
            onConfirm={() => {
              handleBlock(userId, isBlocked);
            }}
            onClose={() => {
              setIsConfirmModalOpen(false);
            }}
          />
        )}
        {openAddMoney && (
          <WalletTransactionModal
            open={openAddMoney}
            close={() => setOpenAddMoney(false)}
            title="Add Money to Wallet"
            userId={id}
            orderId={orderId}
            tableId={tableId}
            transaction="credit"
            notify={notify}
          />
        )}
        {openDeductMoney && (
          <WalletTransactionModal
            open={openDeductMoney}
            close={() => setOpenDeductMoney(false)}
            title="Deduct Money from Wallet"
            userId={id}
            transaction="debit"
            notify={notify}
          />
        )}
        {openManageRoles && (
          <ManageRoleModal
            open={openManageRoles}
            close={() => setOpenManageRoles(false)}
            userId={id}
            userRoles={get(userData, "roles", [])}
          />
        )}
      </>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
};

export default memo(Details);
