import {
  TopToolbar,
  ExportButton,
  List,
  SelectColumnsButton,
  TextField,
  Filter,
  SearchInput,
  useRefresh,
  usePermissions,
  DatagridConfigurable,
  FunctionField,
} from "react-admin";

import TotalGamesPlayedField from "./TotalGamesPlayed";
import UserNameHyperlink from "../../components/UserNameHyperlink";
import TotalGamesCountField from "./TotalGamesCountField";

const UserStats = (props: object) => {
  const refresh = useRefresh();
  // eslint-disable-next-line

  const { permissions } = usePermissions();

  const ListActions = () => (
    <TopToolbar>
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Mobile Number"
        source="mobileNumber.number"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Users Statistics</h2>

      <List {...props} actions={<ListActions />} filters={<UserNameFilter />}>
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="username" to="_id" />
          <TextField
            source="paymentStats.totalDeposits"
            label="Total Deposit Count"
          />
          <FunctionField
            source="paymentStats.totalSuccessDepositsAmount"
            label="Total Deposit Amount"
            render={(record: any) => {
              return "₹" + record.paymentStats.totalSuccessDepositsAmount;
            }}
          />

          <TextField
            source="paymentStats.totalWithdrawals"
            label="Total Withdrawal Count"
          />
          <FunctionField
            source="paymentStats.totalSuccessWithdrawalsAmount"
            label="Total Withdrawal Amount"
            render={(record: any) => {
              return "₹" + record.paymentStats.totalSuccessWithdrawalsAmount;
            }}
          />
          <TextField
            source="paymentStats.totalRefunds"
            label="Total Refund Count"
          />
          <FunctionField
            source="paymentStats.totalRefundAmount"
            label="Total Refund Amount"
            render={(record: any) => {
              return "₹" + record.paymentStats.totalRefundAmount;
            }}
          />
          <TotalGamesCountField label="Total Games Played" />
          <TotalGamesPlayedField
            gameType="ludo"
            label="Total Ludo Games Played"
          />
          <TotalGamesPlayedField
            gameType="callbreak"
            label="Total Callbreak Games Played"
          />
          <TotalGamesPlayedField
            gameType="skillpatti"
            label="Total Skillpatti Games Played"
          />
          <TotalGamesPlayedField
            gameType="snakesandladders"
            label="Total Snake and Ladders Games Played"
          />
          <TotalGamesPlayedField
            gameType="rummy"
            label="Total Rummy Games Played"
          />
          <TotalGamesPlayedField
            gameType="ludoMegaTournament"
            label="Total Mega Tournament Games Played"
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default UserStats;
