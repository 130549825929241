import { useState } from "react";
import Box from "@mui/material/Box";
import PaymentsIcon from "@mui/icons-material/Payments";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import BarChartIcon from "@material-ui/icons/BarChart";
import CreateIcon from "@mui/icons-material/Create";
import PagesIcon from "@mui/icons-material/Pages";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import CasinoIcon from "@mui/icons-material/Casino";
import GridViewIcon from "@mui/icons-material/GridView";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
} from "react-admin";

import {
  PersonAdd,
  People,
  Feedback,
  BugReport,
  CreditCard,
  Fingerprint,
  HourglassTop,
  AddCard,
  Rule,
  DashboardCustomizeRounded,
  VideogameAsset,
  Security,
  DashboardOutlined,
  List,
  CheckBox,
  AttachMoney,
  Block,
  AppBlocking,
  AdminPanelSettings,
  CardGiftcardRounded,
  CardGiftcard,
  SystemSecurityUpdateGood,
  Android,
  Campaign,
  InsertChart,
} from "@mui/icons-material";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import SubMenu from "./SubMenu";
import StyleIcon from "@mui/icons-material/Style";

type MenuName =
  | "payoutMenu"
  | "withdrawalsMenu"
  | "kycMenu"
  | "gameMenu"
  | "usersMenu"
  | "gameRecords"
  | "couponMenu"
  | "TournamentMenu"
  | "MegaTournamentMenu"
  | "userMenu"
  | "spGameMenu"
  | "rummyGameMenu"
  | "cbGameMenu"
  | "snlGameMenu"
  | "transactionsMenu"
  | "ludoGameMenu"
  | "depositsMenu";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    payoutMenu: true,
    gameMenu: true,
    usersMenu: true,
    kycMenu: true,
    userMenu: true,
    gameRecords: true,
    withdrawalsMenu: true,
    couponMenu: true,
    TournamentMenu: true,
    MegaTournamentMenu: true,
    spGameMenu: true,
    rummyGameMenu: true,
    cbGameMenu: true,
    snlGameMenu: true,
    ludoGameMenu: true,
    transactionsMenu: true,
    depositsMenu: true,
  });
  const { permissions } = usePermissions();
  const [open] = useSidebarState();
  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  // eslint-disable-next-line
  const toggleGameMenu = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  // eslint-disable-next-line
  const toggleGameRecords = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  return (
    <Box
      sx={{
        width: open ? 300 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {permissions.includes("developer") && (
        <>
          <MenuItemLink
            to="/api/users/appVersions/dashboard"
            state={{ _scrollToTop: true }}
            primaryText={`Versions Dashboard`}
            leftIcon={<Android />}
            dense={dense}
          />
        </>
      )}
      {permissions.includes("finance") && (
        <>
          <MenuItemLink
            to="/api/payment/revenue-dashboard"
            state={{ _scrollToTop: true }}
            primaryText={`Revenue Dashboard`}
            leftIcon={<CurrencyRupeeIcon />}
            dense={dense}
          />

          <SubMenu
            handleToggle={() => handleToggle("usersMenu")}
            isOpen={state.usersMenu}
            name="Users"
            icon={<CreditCard />}
            dense={dense}
          >
            <MenuItemLink
              to="/api/users/stats/counts"
              state={{ _scrollToTop: true }}
              primaryText={`Users Stats`}
              leftIcon={<InsertChart />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("gameMenu")}
            isOpen={state.gameMenu}
            name="Games Details"
            icon={<SportsEsportsIcon />}
            dense={dense}
          >
            <SubMenu
              handleToggle={() => handleToggle("ludoGameMenu")}
              isOpen={state.ludoGameMenu}
              name="Ludo Empire"
              icon={<CasinoIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/game-table/ludo-history"
                state={{ _scrollToTop: true }}
                primaryText={`Ludo History`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/users/revenue/report"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<ShowChartIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("snlGameMenu")}
              isOpen={state.snlGameMenu}
              name="SnakeAndLadder"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/snl-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/snakeandladder-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("spGameMenu")}
              isOpen={state.spGameMenu}
              name="Skillpatti Empire"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/sp/dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />

              <MenuItemLink
                to="/api/sp-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("rummyGameMenu")}
              isOpen={state.rummyGameMenu}
              name="Rummy"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/re-round-history"
                state={{ _scrollToTop: true }}
                primaryText={`Rummy Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("cbGameMenu")}
              isOpen={state.cbGameMenu}
              name="Callbreak Empire"
              icon={<VideogameAsset />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/callbreak-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/cbr-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle("TournamentMenu")}
              isOpen={state.TournamentMenu}
              name="Tournament"
              icon={<PagesIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/mega-tournament"
                state={{ _scrollToTop: true }}
                primaryText={`Mega Tournament History`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/mega-tournament/dashboard/stats"
                state={{ _scrollToTop: true }}
                primaryText={`Mega Tournament Dashboard`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle("transactionsMenu")}
            isOpen={state.transactionsMenu}
            name="Transactions"
            icon={<CreditCard />}
            dense={dense}
          >
            <MenuItemLink
              to="/api/payment/deposit"
              state={{ _scrollToTop: true }}
              primaryText="Deposits"
              leftIcon={<AttachMoney />}
              dense={dense}
            />

            <MenuItemLink
              to="/api/payment/withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Withdrawals"
              leftIcon={<PaymentsIcon />}
              dense={dense}
              title="withdrawals"
            />

            <MenuItemLink
              to="/api/transactions/admin-transaction"
              state={{ _scrollToTop: true }}
              primaryText={`Admin Transactions`}
              leftIcon={<AdminPanelSettings />}
              dense={dense}
            />
            <MenuItemLink
              to="api/payment/dashboard"
              state={{ _scrollToTop: true }}
              primaryText={`Payment Dashboard`}
              leftIcon={<DashboardCustomizeRounded />}
              dense={dense}
            />
            <MenuItemLink
              to="/api/payment/tax-deductions"
              state={{ _scrollToTop: true }}
              primaryText={`TDS Details`}
              leftIcon={<AttachMoney />}
              dense={dense}
            />
          </SubMenu>
        </>
      )}
      {permissions.includes("support") && (
        <>
          <MenuItemLink
            to="/api/users/stats/counts"
            state={{ _scrollToTop: true }}
            primaryText={`Users Stats`}
            leftIcon={<InsertChart />}
            dense={dense}
          />
          <SubMenu
            handleToggle={() => handleToggle("gameMenu")}
            isOpen={state.userMenu}
            name="Games Details"
            icon={<SportsEsportsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/api/game-table/ludo-history"
              state={{ _scrollToTop: true }}
              primaryText={`Ludo History`}
              leftIcon={<List />}
              dense={dense}
            />
            <SubMenu
              handleToggle={() => handleToggle("snlGameMenu")}
              isOpen={state.snlGameMenu}
              name="Snake And Ladders"
              icon={<VideogameAsset />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/snl-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle("spGameMenu")}
              isOpen={state.spGameMenu}
              name="Skillpatti Empire"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/sp-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle("cbGameMenu")}
              isOpen={state.cbGameMenu}
              name="Callbreak Empire"
              icon={<VideogameAsset />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/cbr-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("rummyGameMenu")}
                isOpen={state.rummyGameMenu}
                name="Rummy"
                icon={<SportsBaseballIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="/api/re-round-history"
                  state={{ _scrollToTop: true }}
                  primaryText={`Rummy Histories`}
                  leftIcon={<HistoryToggleOffIcon />}
                  dense={dense}
                />
                </SubMenu>
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle("transactionsMenu")}
            isOpen={state.transactionsMenu}
            name="Transactions"
            icon={<CreditCard />}
            dense={dense}
          >
            <MenuItemLink
              to="/api/payment/deposit"
              state={{ _scrollToTop: true }}
              primaryText="Deposits"
              leftIcon={<AttachMoney />}
              dense={dense}
            />

            <MenuItemLink
              to="/api/payment/withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Withdrawals"
              leftIcon={<PaymentsIcon />}
              dense={dense}
              title="withdrawals"
            />

            <MenuItemLink
              to="/api/users/referral/transaction"
              state={{ _scrollToTop: true }}
              primaryText={`Referral Transactions`}
              leftIcon={<PersonAdd />}
              dense={dense}
            />
            <MenuItemLink
              to="/api/transactions/bonus-transaction"
              state={{ _scrollToTop: true }}
              primaryText={`Bonus Transactions`}
              leftIcon={<CardGiftcardRounded />}
              dense={dense}
            />
          </SubMenu>
        </>
      )}

      {permissions.includes("admin") && (
        <>
          <MenuItemLink
            to="/api/payment/revenue-dashboard"
            state={{ _scrollToTop: true }}
            primaryText={`Revenue Dashboard`}
            leftIcon={<CurrencyRupeeIcon />}
            dense={dense}
          />

          <MenuItemLink
            to="/api/users/appVersions/dashboard"
            state={{ _scrollToTop: true }}
            primaryText={`Versions Dashboard`}
            leftIcon={<Android />}
            dense={dense}
          />
          <SubMenu
            handleToggle={() => handleToggle("usersMenu")}
            isOpen={state.usersMenu}
            name="Users"
            icon={<CreditCard />}
            dense={dense}
          >
            <MenuItemLink
              to="/api/users"
              state={{ _scrollToTop: true }}
              primaryText={`Users Details`}
              leftIcon={<List />}
              dense={dense}
            />
            <MenuItemLink
              to="/api/users/stats/counts"
              state={{ _scrollToTop: true }}
              primaryText={`Users Stats`}
              leftIcon={<InsertChart />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("gameMenu")}
            isOpen={state.gameMenu}
            name="Games Details"
            icon={<SportsEsportsIcon />}
            dense={dense}
          >
            <SubMenu
              handleToggle={() => handleToggle("ludoGameMenu")}
              isOpen={state.ludoGameMenu}
              name="Ludo Empire"
              icon={<CasinoIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/users/revenue/report"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<ShowChartIcon />}
                dense={dense}
              />

              <MenuItemLink
                to="/api/game-table/ludo-history"
                state={{ _scrollToTop: true }}
                primaryText={`Ludo History`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("snlGameMenu")}
              isOpen={state.snlGameMenu}
              name="SnakeAndLadder"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/snakeandladder-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/snl-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />

              <MenuItemLink
                to="admin/live-games/snakesandladders"
                state={{ _scrollToTop: true }}
                primaryText={`Live Games`}
                leftIcon={<LiveTvIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("spGameMenu")}
              isOpen={state.spGameMenu}
              name="Skillpatti Empire"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/sp/dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />

              <MenuItemLink
                to="/api/sp-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="admin/live-games/skillpatti"
                state={{ _scrollToTop: true }}
                primaryText={`Live Games`}
                leftIcon={<LiveTvIcon />}
                dense={dense}
              />
              <SubMenu
                handleToggle={() => handleToggle("rummyGameMenu")}
                isOpen={state.rummyGameMenu}
                name="Rummy"
                icon={<SportsBaseballIcon />}
                dense={dense}
              >
                <MenuItemLink
                  to="/api/rummy/dashboard"
                  state={{ _scrollToTop: true }}
                  primaryText={`Rummy Dashboard`}
                  leftIcon={<GridViewIcon />}
                  dense={dense}
                />
                <MenuItemLink
                  to="/api/re-round-history"
                  state={{ _scrollToTop: true }}
                  primaryText={`Rummy Histories`}
                  leftIcon={<HistoryToggleOffIcon />}
                  dense={dense}
                />
                <MenuItemLink
                  to="admin/live-games/rummyempire"
                  state={{ _scrollToTop: true }}
                  primaryText={`Live Games`}
                  leftIcon={<LiveTvIcon />}
                  dense={dense}
                />
              </SubMenu>
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle("cbGameMenu")}
              isOpen={state.cbGameMenu}
              name="Callbreak Empire"
              icon={<VideogameAsset />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/callbreak-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/cbr-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="admin/live-games/callbreak"
                state={{ _scrollToTop: true }}
                primaryText={`Live Games`}
                leftIcon={<LiveTvIcon />}
                dense={dense}
              />
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle("TournamentMenu")}
              isOpen={state.TournamentMenu}
              name="Tournament"
              icon={<PagesIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/ludo/tournament"
                state={{ _scrollToTop: true }}
                primaryText={`Create Tournament`}
                leftIcon={<CreateIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/tournament"
                state={{ _scrollToTop: true }}
                primaryText={`Tournament Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />

              <MenuItemLink
                to="/ludo/mega-tournament"
                state={{ _scrollToTop: true }}
                primaryText={`Create Mega Tournament`}
                leftIcon={<CreateIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/mega-tournament"
                state={{ _scrollToTop: true }}
                primaryText={`Mega Tournament History`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/mega-tournament/dashboard/stats"
                state={{ _scrollToTop: true }}
                primaryText={`Mega Tournament Dashboard`}
                leftIcon={<DashboardCustomizeRounded />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("couponMenu")}
              isOpen={state.couponMenu}
              name="Coupon"
              icon={<PagesIcon />}
              dense={dense}
            >
            <MenuItemLink
                to="/promo/coupons"
                state={{ _scrollToTop: true }}
                primaryText={`Create Coupons `}
                leftIcon={<CreateIcon />}
                dense={dense}
              />
               <MenuItemLink
                to="/api/Coupon/coupon-history"
                state={{ _scrollToTop: true }}
                primaryText={`Coupon Dashboard `}
                leftIcon={<DashboardCustomizeRounded />}
                dense={dense}
              />
              </SubMenu>
          </SubMenu>
         
          <SubMenu
            handleToggle={() => handleToggle("transactionsMenu")}
            isOpen={state.transactionsMenu}
            name="Transactions"
            icon={<CreditCard />}
            dense={dense}
          >
            <MenuItemLink
              to="api/payment/dashboard"
              state={{ _scrollToTop: true }}
              primaryText="Payment Dashboard"
              leftIcon={<DashboardCustomizeRounded />}
              dense={dense}
            />

            <MenuItemLink
              to="/api/payment/deposit"
              state={{ _scrollToTop: true }}
              primaryText="Deposits"
              leftIcon={<AttachMoney />}
              dense={dense}
            />

            <MenuItemLink
              to="/api/payment/withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Withdrawals"
              leftIcon={<PaymentsIcon />}
              dense={dense}
              title="withdrawals"
            />

            <MenuItemLink
              to="/api/users/referral/transaction"
              state={{ _scrollToTop: true }}
              primaryText={`Referral History`}
              leftIcon={<PersonAdd />}
              dense={dense}
            />
            <MenuItemLink
              to="/api/transactions/bonus-transaction"
              state={{ _scrollToTop: true }}
              primaryText={`Bonus Transactions`}
              leftIcon={<CardGiftcardRounded />}
              dense={dense}
            />
            <MenuItemLink
              to="/api/transactions/admin-transaction"
              state={{ _scrollToTop: true }}
              primaryText={`Admin Transactions`}
              leftIcon={<AdminPanelSettings />}
              dense={dense}
            />

            <MenuItemLink
              to="/api/payment/tax-deductions"
              state={{ _scrollToTop: true }}
              primaryText={`TDS Details`}
              leftIcon={<AttachMoney />}
              dense={dense}
            />
          </SubMenu>
        </>
      )}
    </Box>
  );
};
export default Menu;
