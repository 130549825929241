import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
  Button,
} from "react-admin";
import { Stack, Typography } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";
import { callRestApi } from "../utils/callRestApi";

const Deposits = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "success", name: "Success" },
        { id: "failed", name: "Failed" },
        { id: "pending", name: "Pending" },
        { id: "refund", name: "Refund" },
      ]}
    />,
    <SelectArrayInput
      label="Filter by Gateway"
      source="gateway"
      choices={[
        { id: "juspay", name: "Juspay" },
        { id: "Cashfree", name: "Cashfree" },
      ]}
    />,
    <SelectArrayInput
    label="Filter by  Payment method"
    source="paymentMethod"
    choices={[
      { id: "UPI", name: "UPI" },
      { id: "Card", name: "Card" },
      { id: "NetBanking", name: "Net Banking" },
    ]}
  />,
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Amount"
        source="amount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const handleDownloadInvoice = async (orderId: string, overwrite: false) => {
    try {
      const endpoint = "/payment/generate-invoice";
      const invoiceUrl = await callRestApi(endpoint, "POST", {
        orderId: orderId,
        overwrite
      }) 
      if (invoiceUrl && invoiceUrl.data && typeof invoiceUrl.data === 'string') {
        const downloadLink = document.createElement("a");
        downloadLink.href = invoiceUrl.data;
        downloadLink.download = `invoice_${orderId}.pdf`;
        downloadLink.click();
        downloadLink.remove();
      }
      
    } 
    catch (error) {
      console.error("Error generating invoice:", error);
    }
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Deposits</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <UserNameHyperlink
            label="User Name"
            source="userName"
            to="userId"
          />
          <TextField source="amount" />
          <TextField source="gateway" />
          <TextField source="paymentMethod" />

          <FunctionField
            label="Status"
            render={(record: any) => {
              if (record.status === "pending") {
                return <ChipField source="status" color="warning" />;
              } else if (record.status === "success") {
                return <ChipField source="status" color="success" />;
              } else {
                return <ChipField source="status" color="error" />;
              }
            }}
          />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <FunctionField
            label="Invoice"
            render={(record: any) =>
              (permissions.includes("admin") ||
                permissions.includes("finance")) &&
              record.status === "success" ? (
                <Button
                  label="Invoice"
                  onClick={() =>
                    handleDownloadInvoice(record.orderId, false)
                  }
                />
              ) : null
            }
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Deposits;
