import {
  TopToolbar,
  ExportButton,
  List,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  WrapperField,
  ShowButton,
  FunctionField,
  Button as RaButton,
  useRefresh,
  usePermissions,
  DatagridConfigurable,
  FilterForm,
  DateTimeInput,
  FilterButton,
  useListContext,
} from "react-admin";

import { callRestApi } from "../../utils/callRestApi";
import { toast } from "react-toastify";
import { useState } from "react";
import { Stack } from "rsuite";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
  Button as MuiButton,
  Typography,
} from "@mui/material";
import { AxiosError, AxiosResponse } from 'axios';

interface AccountResponseItem {
  upiId?: string;
  status: string;
  account?: {
    accountNo?: string;
    ifscCode?: string;
  };
}
interface AccountDetails {
  upiIds: string[];
  bankAccounts: Array<{ accountNo: string; ifscCode: string }>;
}

const Users = (props: object) => {
  const refresh = useRefresh();
  const { permissions } = usePermissions();

  const [userData, setUserData] = useState<{
    _id: string;
    isBlocked: boolean;
  } | null>(null);

  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState("");
  const [loading, setLoading] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [accountDetails, setAccountDetails] = useState<{
    upiIds: string[];
    accountNumbers: string[];
    ifscCodes: string[];
  } | null>(null);

  const handleOpenModal = (record: any) => {
    setUserData({ _id: record.id, isBlocked: record.isBlocked });
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setReason("");
  };

  const handleBlock = async () => {
    try {
      if (!permissions.includes("admin")) {
        toast.error("You don't have access to this functionality");
        return;
      }

      const endpoint = "/users/change-block-status";
      const url = `${endpoint}`;
      await callRestApi(url, "PATCH", {
        userId: userData?._id,
        shouldBlock: !userData?.isBlocked,
        reason,
      });
      refresh();
      toast.success(
        !userData?.isBlocked
          ? "Blocked User Successfully!"
          : "Unblocked User Successfully!"
      );
      handleCloseModal();
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };
  const handleDeleteKYC = async (record: { _id: string }) => {
    try {
      setLoading(true);
      const userId = record._id;
      const url = '/users/delete-kyc';
      
      const { data, status } = await callRestApi(url, "DELETE", { userId }) as AxiosResponse;
      
      if (status === 200) {
        toast("KYC Deleted Successfully", {
          type: "success",
        });
      } else {
       
        throw new Error(data.message || "Unexpected response from server");
      }
    } catch (error: unknown) {
      console.error('Delete KYC error:', error);
      
      if (error instanceof AxiosError) {
        if (error.response?.status === 400 && error.response.data?.message === "KYC doesn't exist") {
          toast(" KYC Doesn't exist in DB.", {
            type: "info",
          });
        } else {
          toast(error.response?.data?.message || "Failed to delete KYC", {
            type: "error",
          });
        }
      } else if (error instanceof Error) {
        toast(error.message, { type: "error" });
      } else {
        toast("An unknown error occurred", { type: "error" });
      }
    } finally {
      setDeleteConfirmModalOpen(false);
      setLoading(false);
      refresh();
    }
  };
  
  

  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Name"
        source="name"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Mobile Number"
        source="mobileNumber.number"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search App Version"
        source="build.appVersion"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search App Code"
        source="build.appCode"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const formatWalletValue = (value: any) => {
    if (!isNaN(parseFloat(value))) {
      const stringValue = String(value);
      const decimalIndex = stringValue.indexOf(".");
      if (decimalIndex !== -1) {
        return stringValue.slice(0, decimalIndex + 3);
      } else {
        return stringValue;
      }
    } else {
      return "";
    }
  };

  const handleAccountDetails = async (record: any) => {
    try {
      const userId = record._id;
  
      // URL for the API call
      const url = `/api/payment/account-details?count=100&filter={"userId":"${userId}"}&key=ttYXm2U4V1Qwah5893ewp78494XP19IZMl&skip=0&sortBy=createdAt&sortDir=-1`;
      const response = await callRestApi(url, "GET");
  
      if (response && response.data && response.data.items.length > 0) {
        const items: AccountResponseItem[] = response.data.items.filter(
          (item: AccountResponseItem) => item.status === "success"
        );
  
        // Create a map for unique account numbers with their IFSC codes
        const accountDetailsMap = new Map<string, string>();
  
        items.forEach((item) => {
          if (item.account?.accountNo) {
            // Ensure only unique account numbers are stored
            if (!accountDetailsMap.has(item.account.accountNo)) {
              accountDetailsMap.set(item.account.accountNo, item.account.ifscCode || "");
            }
          }
        });
  
        const upiIds = [
          ...new Set(
            items
              .filter((item): item is AccountResponseItem & { upiId: string } => !!item.upiId)
              .map((item) => item.upiId!)
          ),
        ];
  
        setAccountDetails({
          upiIds,
          accountNumbers: Array.from(accountDetailsMap.keys()),
          ifscCodes: Array.from(accountDetailsMap.values()),
        });
  
        setOpenAccountModal(true);
      } else {
        toast.error("No account details found.");
        setAccountDetails(null);
      }
    } catch (error) {
      console.error("Failed to fetch account details:", error);
      toast.error("Failed to fetch account details. Please check the console for more details.");
    }
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Users List</h2>

      <List
        {...props}
        actions={<ListActions />}
        filters={<UserNameFilter />}
        sort={{ field: "_id", order: "DESC" }}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="username" label="User Name" />
          <TextField source="name" label="Name" />
          <FunctionField
            source="wallet.main"
            label="Main Wallet"
            render={(record: any) => formatWalletValue(record.wallet.main)}
          />
          <FunctionField
            source="wallet.win"
            label="Win Wallet"
            render={(record: any) => formatWalletValue(record.wallet.win)}
          />
          <FunctionField
            source="wallet.bonus"
            label="Bonus Wallet"
            render={(record: any) => formatWalletValue(record.wallet.bonus)}
          />
          <TextField source="build.appVersion" label="App Version" />
          <TextField source="build.appCode" label="App Code" />
          <DateField source="createdAt" showTime label="Registered At" />
          {permissions.includes("admin") && (
            <>
              <FunctionField
                label="Actions"
                render={(record: any) => {
                  return (
                    <>
                      <MuiButton
                        sx={{
                          textTransform: "none",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                        variant="contained"
                        onClick={() => handleOpenModal(record)}
                      >
                        {!record.isBlocked ? "Block" : "Unblock"}
                      </MuiButton>
                      <MuiButton
                        sx={{
                          textTransform: "none",
                          marginLeft: 1,
                          backgroundColor: "green",
                          color: "white",
                        }}
                        variant="contained"
                        onClick={() => handleAccountDetails(record)}
                      >
                        Account Details
                      </MuiButton>
                      <MuiButton
                        sx={{
                          textTransform: "none",
                          marginLeft: 1,
                          backgroundColor: "red",
                          color: "white",
                        }}
                        variant="contained"
                        onClick={() => handleDeleteKYC(record)}
                      >
                        Delete KYC
                      </MuiButton>
                    </>
                  );
                }}
              />
            </>
          )}
          <ShowButton />
        </DatagridConfigurable>
      </List>

      {/* Modal for Block/Unblock Reason */}
      <Dialog open={openAccountModal} onClose={() => setOpenAccountModal(false)}>
  <DialogTitle>Account Details</DialogTitle>
  <DialogContent>
    {accountDetails ? (
      <div>
        {accountDetails.upiIds.length > 0 && (
          <>
            <Typography variant="h6">UPI IDs:</Typography>
            {accountDetails.upiIds
              .filter((upiId) => upiId !== "string")
              .map((upiId, index) => (
                <Typography key={index}>{upiId}</Typography>
              ))}
          </>
        )}
        {accountDetails.accountNumbers.length > 0 && accountDetails.ifscCodes.length > 0 && (
          <>
            <Typography variant="h6">Bank Account Details:</Typography>
            <Stack direction="row" spacing={2} style={{ width: "100%" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>
                      Account Number
                    </th>
                    <th style={{ textAlign: "left", padding: "8px", borderBottom: "1px solid #ddd" }}>
                      IFSC Code
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {accountDetails.accountNumbers.map((accountNo, index) => {
                    const ifscCode = accountDetails.ifscCodes[index];
                    return (
                      <tr key={index}>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{accountNo}</td>
                        <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{ifscCode}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Stack>
          </>
        )}
        {accountDetails.upiIds.length === 0 &&
          accountDetails.accountNumbers.length === 0 &&
          accountDetails.ifscCodes.length === 0 && (
            <Typography>No account details available.</Typography>
          )}
      </div>
    ) : (
      <Typography>No account details available.</Typography>
    )}
  </DialogContent>
  <DialogActions>
    <MuiButton onClick={() => setOpenAccountModal(false)} color="primary">
      Close
    </MuiButton>
  </DialogActions>
</Dialog>

    </>
  );
};

export default Users;
