import { memo, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { has, set } from "lodash";
import querystring from "query-string";

import { callRestApi } from "../utils/callRestApi";
import { convertToDotNotation } from "../utils/dotNotationConverter";

const SimpleTable = (props: any) => {
  const { data = [], endPointData, dataMode = "client", filter = {} } = props;
  const columns: GridColDef[] = props.columns;
  const isLeaderBoard = columns.some((column) => column.field === "rank");

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [apiMeta, setApiMeta] = useState({
    totalCount: 0,
    skip: 0,
    count: 5,
  });
  const [payloadMeta, setPayloadMeta] = useState({
    count: isLeaderBoard ? 20 : 5,
    skip: 0,
    page: 0,
    filter: {},
    sortBy: "createdAt",
    sortDir: -1,
  });

  const [selectedGame, setSelectedGame] = useState("Ludo");

  useEffect(() => {
    if (dataMode === "server") {
      fetchData();
    }
    // eslint-disable-next-line
  }, [payloadMeta, selectedGame, filter, endPointData, dataMode]);

  const handleGameChange = (value: string) => {
    setSelectedGame(value);
  };

  const handlePaginationChange = (event: any) => {
    const { page, pageSize } = event;
    setPayloadMeta((prev) => ({
      ...prev,
      skip: Math.abs(page * payloadMeta.count),
      count: pageSize,
      page,
    }));
  };

  const handleSortChange = (event: any) => {
    try {
      const { field, sort } = event[0];
      setPayloadMeta((prev) => ({
        ...prev,
        sortBy: field,
        sortDir: sort === "asc" ? 1 : -1,
      }));
    } catch (error) {
      setPayloadMeta((prev) => ({
        ...prev,
        sortBy: "createdAt",
        sortDir: -1,
      }));
    }
  };

  const handleFilterChange = (event: any) => {
    try {
      const { field, value } = event?.items[0];
      setPayloadMeta((prev) => {
        const filterValue: object = {};
        set(filterValue, field, value);
        return {
          ...prev,
          filter: { ...prev.filter, ...filterValue },
        };
      });
    } catch (error) {
      setPayloadMeta((prev) => ({
        ...prev,
      }));
    }
  };

  const fetchData = async () => {
    let adjustedFilter = { ...payloadMeta.filter, ...filter };

    if (getApiEndpoint() === "/api/re-round-history") {
      adjustedFilter = { "userInfo.userId": filter["userId"] || "" };
    }

    const query = {
      filter: JSON.stringify(convertToDotNotation(adjustedFilter)),
      count: payloadMeta.count,
      skip: payloadMeta.skip,
      sortBy: payloadMeta.sortBy,
      sortDir: payloadMeta.sortDir,
    };

    setLoading(true);
    try {
      const apiResponse = await callRestApi(
        getApiEndpoint(),
        endPointData?.method || "GET",
        {},
        querystring.stringify(query)
      );

      if (apiResponse?.data?.items) {
        setApiData(apiResponse.data.items);
        setApiMeta(apiResponse.data.meta);
      } else {
        console.error("Unexpected API response structure:", apiResponse);
        setApiData([]);
        setApiMeta({ totalCount: 0, skip: 0, count: 0 });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setApiData([]);
      setApiMeta({ totalCount: 0, skip: 0, count: 0 });
    }
    setLoading(false);
  };

  const getApiEndpoint = () => {
    if (endPointData?.apiEndpoint) {
      return endPointData.apiEndpoint;
    } else {
      switch (selectedGame) {
        case "Ludo":
          return "/api/ludo-game-history";
        case "Callbreak":
          return "/api/cbr-game-history";
        case "Skillpatti":
          return "/api/sp-game-histories";
        case "Snakeandladder":
          return "/api/snl-game-history";
        case "Rummy":
          return "/api/rummy-game-history";
        default:
          return "";
      }
    }
  };

  const renderDropdown = endPointData?.apiEndpoint === "";

  return (
    <>
      {renderDropdown && (
        <div style={{ display: "flex" }}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            fontSize="large"
            marginTop="12px"
            marginLeft="5px"
          >
            Select Game:
          </Typography>
          <FormControl sx={{ m: 1, width: 300, marginLeft: "12px" }}>
            <Select
              labelId="game-select-label"
              id="game-select"
              value={selectedGame}
              onChange={(event) =>
                handleGameChange(event.target.value as string)
              }
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (
                  !selected ||
                  (Array.isArray(selected) && selected.length === 0)
                ) {
                  return <em>Select Game</em>;
                }
                return selected;
              }}
            >
              <MenuItem value="Ludo">Ludo</MenuItem>
              <MenuItem value="Callbreak">Callbreak</MenuItem>
              <MenuItem value="Skillpatti">Skillpatti</MenuItem>
              <MenuItem value="Snakeandladder">Snakeladder</MenuItem>
              <MenuItem value="Rummy">Rummy</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      <Box sx={{ height: isLeaderBoard ? 1000 : 400, width: "100%" }}>
        <DataGrid
          rows={dataMode === "client" ? data : apiData}
          columns={columns}
          rowCount={dataMode === "client" ? data.length : apiMeta.totalCount}
          paginationModel={{
            page: payloadMeta.page,
            pageSize: payloadMeta.count,
          }}
          loading={loading}
          pagination
          paginationMode={dataMode}
          pageSizeOptions={
            isLeaderBoard ? [20, 30, 50, 100] : [5, 10, 20, 30, 50, 100]
          }
          disableRowSelectionOnClick
          slots={{ loadingOverlay: LinearProgress, toolbar: GridToolbar }}
          onFilterModelChange={handleFilterChange}
          onSortModelChange={handleSortChange}
          onPaginationModelChange={handlePaginationChange}
          getRowId={(row) => row._id}
          sortModel={isLeaderBoard ? [{ field: "rank", sort: "asc" }] : []}
        />
      </Box>
    </>
  );
};

export default memo(SimpleTable);
