import { useRecordContext } from 'react-admin';

const TotalGamesCountField = ({ label }: any) => {
    const record = useRecordContext();
    if (!record || !record.stats) return null;

    const gameTypes = ['ludo', 'skillpatti', 'snakeAndLadders', 'callbreak','ludoMegaTournament','rummy'];
    const totalGamesPlayed = gameTypes.reduce((total, gameType) => {
        if (!record.stats[gameType]) return total;
        const winMatches = record.stats[gameType].winMatches || 0;
        const lossMatches = record.stats[gameType].lossMatches || 0;
        return total + winMatches + lossMatches;
    }, 0);

    return <span>{totalGamesPlayed}</span>;
};

TotalGamesCountField.defaultProps = { addLabel: true, label: 'Total Games Played' };

export default TotalGamesCountField;