import React from "react";
import { useForm, Controller, FieldValues, get } from "react-hook-form";
import {
  Box,
  Card,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { callRestApi } from "../../utils/callRestApi";
import axios from "axios";
import { Dayjs } from "dayjs";
import config from "../../config";
import { getItemWithExpiry } from "../../utils";
import { toast } from "react-toastify";

interface FormData extends FieldValues {
  isForAll: "all" | "username";
  username?: string;
  couponTitle: string;
  couponCode: string;
  description: string;
  minAmount: string;
  maxAmount: string;
  cashbackAmount: string;
  bonusType: "rupees" | "percentage";
  wallet: "main" | "win" | "bonus";
  usageLimits: string;
  expireAt: Dayjs;
  isOnlyForFirstDeposit: "firstDeposit" | "lifetime";
}

const CreateCoupon: React.FC = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      isForAll: "all",
      bonusType: "rupees",
      wallet: "main",
      isOnlyForFirstDeposit: "lifetime",
    },
  });

  const isForAll = watch("isForAll");

  const onSubmit = async (data: FormData) => {
    const payload = {
      promoCode: data.couponCode,
      description: data.description,
      minAmount: data.minAmount,
      maxAmount: data.maxAmount,
      expireAt: data.expireAt.toISOString(),
      bonusAmount: data.cashbackAmount,
      bonusType: data.bonusType,
      wallet: data.wallet,
      isDeleted: false,
      isForAll: data.isForAll === "all",
      name: data.couponTitle,
      limit: parseInt(data.usageLimits),
      isOnlyForFirstDeposit: data.isOnlyForFirstDeposit === "firstDeposit",
      status: "active",
      username: data.isForAll === "username" ? data.username : undefined,
    };

    try {
      const response = await callRestApi("/promo/coupons", "POST", {
        ...payload,
      });

      if (response && response.data) {
        toast.success("Coupon created successfully!");
        console.log("Coupon created successfully:", response.data);
      } else {
        toast.warn("Response is undefined or does not contain data.");
        console.warn("Response is undefined or does not contain data.");
      }
    } catch (error) {
      toast.error("Error creating coupon");
      console.error("Error creating coupon:", error);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card sx={{ p: 3 }}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <h2> Create Coupons</h2>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="isForAll"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="username"
                      control={<Radio />}
                      label="Username"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>

            {isForAll === "username" && (
              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: "Username is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Enter Username"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Controller
                name="couponTitle"
                control={control}
                rules={{ required: "Coupon Title is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Coupon Title"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="couponCode"
                control={control}
                rules={{ required: "Coupon Code is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Coupon Code"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="description"
                control={control}
                rules={{ required: "Description is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Coupon Description"
                    fullWidth
                    multiline
                    rows={4}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="minAmount"
                control={control}
                rules={{ required: "Minimum Deposit Amount is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Enter Minimum Deposit Amount"
                    fullWidth
                    type="number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="maxAmount"
                control={control}
                rules={{ required: "Maximum Deposit Amount is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Enter Maximum Deposit Amount"
                    fullWidth
                    type="number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="cashbackAmount"
                control={control}
                rules={{ required: "Cashback Amount is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Enter Cashback Amount"
                    fullWidth
                    type="number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="bonusType"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="rupees"
                      control={<Radio />}
                      label="Rupees"
                    />
                    <FormControlLabel
                      value="percentage"
                      control={<Radio />}
                      label="Percentage"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Select Cashback Credit Wallet</InputLabel>
              <Controller
                name="wallet"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="main"
                      control={<Radio />}
                      label="Main Wallet"
                    />
                    <FormControlLabel
                      value="win"
                      control={<Radio />}
                      label="Win Wallet"
                    />
                    <FormControlLabel
                      value="bonus"
                      control={<Radio />}
                      label="Bonus Wallet"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="usageLimits"
                control={control}
                rules={{ required: "Usage Limits is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Enter Usage Limits"
                    fullWidth
                    type="number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Controller
                  name="expireAt"
                  control={control}
                  rules={{ required: "Expiry Date and Time is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      {...field}
                      label="Select Expired Date and Time"
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Applicability</InputLabel>
              <Controller
                name="isOnlyForFirstDeposit"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="firstDeposit"
                      control={<Radio />}
                      label="First Deposit"
                    />
                    <FormControlLabel
                      value="lifetime"
                      control={<Radio />}
                      label="LifeTime"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" type="submit">
                  CREATE
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {}}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </LocalizationProvider>
  );
};

export default CreateCoupon;
