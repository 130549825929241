import React, { useState } from "react";
import {
  TopToolbar,
  ExportButton,
  List,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  FunctionField,
  usePermissions,
  DatagridConfigurable,
  SelectArrayInput,
  FilterForm,
  FilterButton,
  useDataProvider,
  useRefresh,
  Button,
  useNotify,
} from "react-admin";
import {
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { toast } from "react-toastify";
import { getItemWithExpiry } from "../../utils/sessionData";
import axios from "axios";

const transactionFilters = [
  <SelectArrayInput
    label="Filter by Wallet"
    source="wallet"
    choices={[
      { id: "main", name: "Main Wallet" },
      { id: "win", name: "Winning Wallet" },
      { id: "bonus", name: "Bonus Wallet" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by Status"
    source="status"
    choices={[
      { id: "active", name: "Active" },
      { id: "expired", name: "Expired" },
      { id: "deleted", name: "Deleted" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by Cashback"
    source="bonusType"
    choices={[
      { id: "percentage", name: "Percentage" },
      { id: "rupees", name: "Rupees" },
    ]}
  />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const formatAmount = (value: string) => {
  const numberValue = parseFloat(value);
  if (!isNaN(numberValue)) {
    return `₹${numberValue.toFixed(2)}`;
  }
  return `₹0.00`;
};

const DeleteButton = ({ record }: { record: any }) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const openDeleteConfirmation = () => setDeleteConfirmation(true);
  const closeDeleteConfirmation = () => setDeleteConfirmation(false);

  const handleDelete = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user?.token) {
        notify("You have no access", { type: "error" });
        return;
      }

      await axios.delete(
        `https://test.api.empiregames.in/promo/coupons/${record.promoCode}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      notify("Coupon deleted successfully!", { type: "success" });
      refresh();
    } catch (error: unknown) {
      console.error("Delete error:", error);
      if (axios.isAxiosError(error)) {
        notify(
          `Failed to delete the coupon: ${
            error.response?.data?.message || error.message
          }`,
          { type: "error" }
        );
      } else {
        notify("An unknown error occurred while deleting the coupon", {
          type: "error",
        });
      }
    }
    closeDeleteConfirmation();
  };

  return (
    <>
      <Button
        label={record.isDeleted ? "Restore" : "Delete"}
        onClick={openDeleteConfirmation}
      >
        <DeleteForever />
      </Button>
      <Dialog open={deleteConfirmation} onClose={closeDeleteConfirmation}>
        <DialogTitle>
          {record.isDeleted ? "Confirm Restoration" : "Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {record.isDeleted
              ? "Are you sure you want to restore this coupon?"
              : "Are you sure you want to delete this coupon? This action can be undone."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation} label="Cancel" />
          <Button onClick={handleDelete} label="Yes" />
        </DialogActions>
      </Dialog>
    </>
  );
};

const CouponHistory = (props: any) => {
  const { permissions } = usePermissions();

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search Promo Code"
        source="promoCode"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Bonus Amount"
        source="bonusAmount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Coupon History</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
        filterDefaultValues={{ status: "active" }}
      >
        <ListToolbar />
        {permissions.includes("admin") && (
          <DatagridConfigurable bulkActionButtons={false}>
            <TextField source="promoCode" label="Promo Code" />
            <TextField source="description" label="Description" />
            <FunctionField
              source="minAmount"
              label="Min Amount"
              render={(record: any) => formatAmount(record.minAmount)}
            />
            <FunctionField
              source="maxAmount"
              label="Max Amount"
              render={(record: any) => formatAmount(record.maxAmount)}
            />
            <FunctionField
              source="bonusAmount"
              label="Bonus Amount"
              render={(record: any) => formatAmount(record.bonusAmount)}
            />
            <TextField source="bonusType" label="Bonus Type" />
            <FunctionField
              label="Is Deleted"
              render={(record: any) => (
                <span style={{ color: record.isDeleted ? "green" : "red" }}>
                  {record.isDeleted.toString()}
                </span>
              )}
            />
            <TextField source="wallet" label="Wallet" />
            <FunctionField
              label="Is Only For First Deposit"
              render={(record: any) => (
                <span
                  style={{
                    color: record.isOnlyForFirstDeposit ? "green" : "red",
                  }}
                >
                  {record.isOnlyForFirstDeposit.toString()}
                </span>
              )}
            />
            <FunctionField
              label="Is For All"
              render={(record: any) => (
                <span style={{ color: record.isForAll ? "green" : "red" }}>
                  {record.isForAll.toString()}
                </span>
              )}
            />
            <FunctionField
              label="Status"
              render={(record: any) => (
                <span
                  style={{
                    color:
                      record.status === "active"
                        ? "green"
                        : record.status === "expired"
                        ? "red"
                        : "blue",
                  }}
                >
                  {record.status}
                </span>
              )}
            />
            <DateField source="expireAt" showTime label="Coupon Expire Date" />
            <FunctionField
              label="Actions"
              render={(record: any) => <DeleteButton record={record} />}
            />
          </DatagridConfigurable>
        )}
      </List>
    </>
  );
};

export default CouponHistory;
