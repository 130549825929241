import { Link, useRecordContext } from "react-admin";

const UserNameHyperlink = (props: any) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <Link to={`/api/users/${record[props.to]}/show`}>{record[props.source]}</Link>
  );
};
UserNameHyperlink.defaultProps = {
  source: "userName",
  to: "userId",
};

export default UserNameHyperlink;
