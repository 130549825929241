import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Typography, Box } from "@mui/material";
import { DateRangePicker } from "rsuite";
import {
  MonetizationOn,
  CheckCircle,
  Cancel,
  HourglassEmpty,
  Restore,
} from "@mui/icons-material";
import StatsCard from "../components/StatsCard";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";
import axios from "axios";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";
import { CSVLink } from "react-csv";

const PaymentDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [stats, setStats] = useState({
    totalDeposits: 0,
    totalSuccessDeposits: 0,
    totalFailedDeposits: 0,
    totalPendingDeposits: 0,
    totalWithdrawals: 0,
    totalSuccessWithdrawals: 0,
    totalFailedWithdrawals: 0,
    totalPendingWithdrawals: 0,
    totalRefunds: 0,
    totalDepositAmount: 0,
    totalSuccessDepositAmount: 0,
    totalFailedDepositAmount: 0,
    totalPendingDepositAmount: 0,
    totalWithdrawalAmount: 0,
    totalSuccessWithdrawalAmount: 0,
    totalFailedWithdrawalAmount: 0,
    totalPendingWithdrawalAmount: 0,
    totalRefundAmount: 0,
    totalWithdrawalTdsAmount: 0,
    totalDepositGstAmount: 0,
    totalConvertedBonusAmount: 0,
    totalConvertedAmount: 0,
    totalSuccessWithdrawalAmountPlayStore: 0,
    totalFailedWithdrawalAmountPlayStore: 0,
    totalPendingWithdrawalAmountPlayStore: 0,
    totalSuccessWithdrawalsPlayStoreCount: 0,
    totalFailedWithdrawalsPlayStoreCount: 0,
    totalPendingWithdrawalsPlayStoreCount: 0,
    totalWithdrawalsPlayStoreCount: 0,
    totalWithdrawalPlayStoreAmount: 0,
  });

  var date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);

      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
      };

      setLoading(true);
      console.log({ config });
      const url = `${config.REST_ENDPOINT}/api/payment/dashboard?key=${
        config.MAINTENANCE_BYPASS_KEY
      }&filter=${encodeURIComponent(JSON.stringify(filter))}`;
     
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response && response.data) {
        const { data } = response;
        setStats(data);
      }

      const {
        dayWisetotalSuccessDepositAmount,
        dayWisetotalSuccessWithdrawalAmount,
        ...csvStats
      } = response.data;
      setCsvData([csvStats]);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <div>
      <h2 style={{ fontFamily: "Playfair Display" }}>Payment Dashboard</h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
        <CSVLink
          data={csvData}
          filename={`Payment_Dashboard_data_${new Date().toLocaleDateString()}.csv`}
          className="btn btn-primary"
          style={{ position: "absolute", left: "93.4%" }}
        >
          <span>Download CSV</span>
        </CSVLink>
        <Typography
          style={{ marginLeft: "10px" }}
          variant="body2"
          color="textSecondary"
        >
          Note: Total Withdrawals also include Created, Accepted, Requested,
          Rejected and Reversed withdrawal requests
        </Typography>
      </div>
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Success Deposits</Typography>
            }
            value={stats.totalSuccessDeposits}
            icon={
              <CheckCircle style={{ fontSize: "20px", color: "#4CAF50" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Success Deposit Amount
              </Typography>
            }
            value={stats.totalSuccessDepositAmount}
            icon={
              <CheckCircle style={{ fontSize: "20px", color: "#4CAF50" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Success Withdrawals
              </Typography>
            }
            value={stats.totalSuccessWithdrawals}
            icon={
              <CheckCircle style={{ fontSize: "20px", color: "#4CAF50" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Success Withdrawal Amount
              </Typography>
            }
            value={stats.totalSuccessWithdrawalAmount}
            icon={
              <CheckCircle style={{ fontSize: "20px", color: "#4CAF50" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Failed Deposits</Typography>
            }
            value={stats.totalFailedDeposits}
            icon={<Cancel style={{ fontSize: "20px", color: "#F44336" }} />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Failed Deposit Amount
              </Typography>
            }
            value={stats.totalFailedDepositAmount}
            icon={<Cancel style={{ fontSize: "20px", color: "#F44336" }} />}
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Failed Withdrawals
              </Typography>
            }
            value={stats.totalFailedWithdrawals}
            icon={<Cancel style={{ fontSize: "20px", color: "#F44336" }} />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Failed Withdrawal Amount
              </Typography>
            }
            value={stats.totalFailedWithdrawalAmount}
            icon={<Cancel style={{ fontSize: "20px", color: "#F44336" }} />}
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Pending Deposits</Typography>
            }
            value={stats.totalPendingDeposits}
            icon={
              <HourglassEmpty style={{ fontSize: "20px", color: "#FFC107" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Pending Deposit Amount
              </Typography>
            }
            value={stats.totalPendingDepositAmount}
            icon={
              <HourglassEmpty style={{ fontSize: "20px", color: "#FFC107" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Pending Withdrawals
              </Typography>
            }
            value={stats.totalPendingWithdrawals}
            icon={
              <HourglassEmpty style={{ fontSize: "20px", color: "#FFC107" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Pending Withdrawal Amount
              </Typography>
            }
            value={stats.totalPendingWithdrawalAmount}
            icon={
              <HourglassEmpty style={{ fontSize: "20px", color: "#FFC107" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={<Typography fontWeight="bold">Total Deposits</Typography>}
            value={stats.totalDeposits}
            icon={
              <MonetizationOn style={{ fontSize: "20px", color: "#6699CC" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Deposit Amount</Typography>
            }
            value={stats.totalDepositAmount}
            icon={
              <MonetizationOn style={{ fontSize: "20px", color: "#6699CC" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={<Typography fontWeight="bold">Total Withdrawals</Typography>}
            value={stats.totalWithdrawals}
            icon={
              <MonetizationOn style={{ fontSize: "20px", color: "#6699CC" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Withdrawal Amount</Typography>
            }
            value={stats.totalWithdrawalAmount}
            icon={
              <MonetizationOn style={{ fontSize: "20px", color: "#6699CC" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Converted Bonus Amount
              </Typography>
            }
            value={
              stats.totalConvertedBonusAmount
                ? stats.totalConvertedBonusAmount.toFixed(2)
                : 0
            }
            icon={
              <MonetizationOn style={{ fontSize: "20px", color: "#6FDCE3" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Deposit GST Amount{" "}
              </Typography>
            }
            value={
              stats.totalDepositGstAmount
                ? stats.totalDepositGstAmount.toFixed(2)
                : 0
            }
            icon={
              <MonetizationOn style={{ fontSize: "20px", color: "#6FDCE3" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Withdrawal TDS Amount{" "}
              </Typography>
            }
            value={
              stats.totalWithdrawalTdsAmount
                ? stats.totalWithdrawalTdsAmount.toFixed(2)
                : 0
            }
            icon={
              <MonetizationOn style={{ fontSize: "20px", color: "#6FDCE3" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {/* <StatsCard

          /> */}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Converted Amount </Typography>
            }
            value={
              stats.totalConvertedAmount
                ? stats.totalConvertedAmount.toFixed(2)
                : 0
            }
            icon={
              <MonetizationOn style={{ fontSize: "20px", color: "#6FDCE3" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={<Typography fontWeight="bold">Total Refunds</Typography>}
            value={stats.totalRefunds}
            icon={<Restore style={{ fontSize: "20px", color: "#9E9E9E" }} />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Refund Amount</Typography>
            }
            value={stats.totalRefundAmount}
            icon={<Restore style={{ fontSize: "20px", color: "#9E9E9E" }} />}
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {/* <StatsCard
          /> */}
        </Grid>
      </Grid>

      <div style={{ marginTop: "20px" }}>
        <Typography
          variant="h6"
          style={{
            marginTop: -20,
            textAlign: "center",
            marginBottom: 20,
            backgroundColor: "#2196F3",
            color: "white",
          }}
        >
          Playstore Section
        </Typography>
      </div>

      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Success Withdrawal Amount{" "}
              </Typography>
            }
            value={
              stats.totalSuccessWithdrawalAmountPlayStore
                ? stats.totalSuccessWithdrawalAmountPlayStore.toFixed(2)
                : 0
            }
            icon={
              <CheckCircle style={{ fontSize: "20px", color: "#4CAF50" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Failed Withdrawal Amount{" "}
              </Typography>
            }
            value={
              stats.totalFailedWithdrawalAmountPlayStore
                ? stats.totalFailedWithdrawalAmountPlayStore.toFixed(2)
                : 0
            }
            icon={<Cancel style={{ fontSize: "20px", color: "#F44336" }} />}
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Pending Withdrawal Amount{" "}
              </Typography>
            }
            value={
              stats.totalPendingWithdrawalAmountPlayStore
                ? stats.totalPendingWithdrawalAmountPlayStore.toFixed(2)
                : 0
            }
            icon={
              <HourglassEmpty style={{ fontSize: "20px", color: "#FFC107" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Withdrawal Amount</Typography>
            }
            value={stats.totalWithdrawalPlayStoreAmount}
            icon={
              <HourglassEmpty style={{ fontSize: "20px", color: "#FFC107" }} />
            }
            isAmount={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Success Withdrawal Count{" "}
              </Typography>
            }
            value={stats.totalSuccessWithdrawalsPlayStoreCount}
            icon={
              <CheckCircle style={{ fontSize: "20px", color: "#4CAF50" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Failed Withdrawal Count{" "}
              </Typography>
            }
            value={stats.totalFailedWithdrawalsPlayStoreCount}
            icon={<Cancel style={{ fontSize: "20px", color: "#F44336" }} />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">
                Total Pending Withdrawal Count
              </Typography>
            }
            value={stats.totalPendingWithdrawalsPlayStoreCount}
            icon={
              <HourglassEmpty style={{ fontSize: "20px", color: "#FFC107" }} />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Withdrawal Count</Typography>
            }
            value={stats.totalWithdrawalsPlayStoreCount}
            icon={
              <HourglassEmpty style={{ fontSize: "20px", color: "#FFC107" }} />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentDashboard;
